<template>
  <div class="component-page2">
    <img src="./page2.jpg" style="width: 100%" alt="">
  </div>
</template>

<script>
export default {
  name: "page2",
  components: {},
  props: {},
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  filters: {},
  watch: {},
  computed: {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.component-page2 {

}
</style>
<style lang="scss">
.component-page2_body { //dialog使用 customClass="xxx_body"

}
</style>
